import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/job'
const BL_URL = '/api/bill-of-lading'

class JobService extends ApiService {
    constructor(){
        super()
    } 
    getUrl(){
        return URL
    }
    createBooking(id, booking){
        return http.post(`${URL}/${id}/booking`, booking);
    }
    addUnitsToBooking(id, units){
        return http.put(`${URL}/${id}/booking/add-units`, {units: units});
    }
    changePorts(booking, job, voyageInfo){
        return http.put(`${URL}/booking/change-ports`, {booking: booking, pol: job.port_of_loading_id, pod: job.port_of_discharge_id, voyageInfo: voyageInfo});
    }
    checkForTransshipments(bookings){
        return http.post(`${URL}/booking/check-transshipments`, {bookings: bookings});
    }
    setPendingBooking(id, booking){
        return http.put(`${URL}/${id}/booking`, booking);
    }
    createOrUpdateBLDetails(bl, id){
        if(id === null)
            return http.post(`${BL_URL}`, bl);
        return http.put(`${BL_URL}/${id}?update_general_details=true`, bl);
    }
    updateBLUnits(bl, id){
        return http.put(`${BL_URL}/${id}?update_job_units=true`, bl);
    }
    createOrUpdateQuotation(item, id){
        return http.put(`${URL}/${id}`, { 'additional_quotes': item});
    }
    deleteBL(id){
        return http.delete(`${BL_URL}/${id}`);
    }
    downloadPDF(type, value){
        if(type == "bill-of-lading"){
            return http.get(`${BL_URL}/download/${value}`, {
                responseType: 'blob'
            });
        }
        return http.get(`${URL}/download/${type}/${value}`, {
            responseType: 'blob'
        })
    }
    downloadExcel(type, value){
        return http.get(`${BL_URL}/download-${type}/${value}`, {
            responseType: 'blob'
        });
    }
    emailDocuments(id, unitIds, email_customer, email_user, email_otherlist) {
        return http.post(`${URL}/${id}/email-documents`, {
            unitIds: unitIds,
            email_customer: email_customer, 
            email_user: email_user, 
            email_otherlist: email_otherlist
        });
    }
    addUsers(users, jobId){
        return http.put(`${URL}/${jobId}/add-users`, {users: users});
    }
    addMessage(message, jobId){
        var params = `?content=` + message.replaceAll("\n", "\\n");
        return http.post(`${URL}/${jobId}/message`+params);
    }
    sendToTeams(bookingId, destination){
        return http.post(`${URL}/booking/${bookingId}/teams?destination=${destination}`);
    }
}

export default new JobService()