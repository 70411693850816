import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/voyage'

class VoyageModService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    getPortsAndOperators(id, getCustomersToo = false){
        const qstring = getCustomersToo ? '?getcustomers=1' : '';
        return http.get(`${this.getUrl()}/${id}/ports-and-operators` + qstring);
    }
    livesearch(query, field, selectFields = ['id'], extraOptions = [], sortField = null) {
        const sortBy = sortField ? sortField : field;
        var params = {
          "lazyEvent": {
            "sortField": this.hasRelation ? ('relation.' + sortBy) : sortBy,
            "sortOrder": sortBy == 'id' ? -1 : 1,
            "searchFrom": "livesearch",
            "searchType": "regex",
            "filters":{
              'global' :{
                "value": query,
                "matchMode":"contains"
              }
            },
            "selectFields": selectFields
          }
        };
        var selectfield = field.split('.');
        if(selectfield.length == 1 && this.hasRelation){
          params.lazyEvent.selectFields.push('relation_id');
          params.lazyEvent.selectFields.push({'relation': ['id', field]});
        }
        else if(selectfield.length == 1) {
          params.lazyEvent.selectFields.push(selectfield[0]);
        } else {
          var selectfieldObject = {}
          selectfieldObject[selectfield[0]] = ['id', selectfield[1]];
          params.lazyEvent.selectFields.push(selectfield[0] + "_id");
          params.lazyEvent.selectFields.push(selectfieldObject);
        }
  
        params.lazyEvent = JSON.stringify(params.lazyEvent);
        params = {...params, ...extraOptions};
        let queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
        const queryUrl = `?page=1&exclude_mainport=1&` + queryParams
        return http.get(`${this.getUrl()}` + queryUrl)
    }
}

export default new VoyageModService()