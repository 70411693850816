<template>
  <div v-if="popupIsOpen" class="modal modal-in-modal show d-block" id="remarksModal" tabindex="-1" aria-labelledby="remarksModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content adjustable">
        <div class="modal-header">
          <h5 class="modal-title" id="remarksModalLabel">{{$t('operations.review_confirm')}}</h5>
          <button type="button" class="btn-close" @click.prevent="$parent.acceptPopupOpen = false" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="d-flex">
            <label for="option-exp" class="col-sm-4 col-form-label-sm">{{$t('sales.valid_from')}}</label>
            <div class="col-sm-8 ps-2 col-form-label-sm"> {{ formatDate($parent.quotation.valid_from, 'shortDate') }} </div>
          </div>
          <div class="d-flex">
            <label for="option-exp" class="col-sm-4 col-form-label-sm">{{$t('sales.valid_to')}}</label>
            <div class="col-sm-8 ps-2 col-form-label-sm"> {{ formatDate($parent.quotation.valid_till, 'shortDate') }} </div>
          </div>
          <div class="d-flex">
            <label for="option-exp" class="col-sm-4 col-form-label-sm">{{$t('navigation.vessels')}}</label>
            <div class="col-sm-8 ps-2 col-form-label-sm"> {{ vesselName }} </div>
          </div>
          <div class="d-flex">
            <label for="option-exp" class="col-sm-4 col-form-label-sm">{{$t('sales.sales_price')}}</label>
            <div class="col-sm-8 ps-2 col-form-label-sm" v-html="$parent.quotation.total_amount"> </div>
          </div>
          <div class="d-flex">
            <label for="option-exp" class="col-sm-4 col-form-label-sm">{{$t('navigation.customers')}}</label>
            <div class="col-sm-8 ps-2 col-form-label-sm"> {{ $parent.quotation.customer ? $parent.quotation.customer.relation.name : '' }} </div>
          </div>
          <div class="d-flex pb-1">
            <label for="option-exp" class="col-sm-4 col-form-label-sm">{{$t('sales.option')}} *</label>
            <div class="col-sm-8 ps-2">
                <select v-model="option_carrier_id" class="form-select form-select-sm" id="option-id" @change="setVesselName">
                    <option :value="null" disabled="">{{ $t('form.select') }}</option>
                    <optgroup v-for="optiongroup, index in forwardingOptions" :key="index" :label="optiongroup.text">
                        <option v-for="option, index2 in optiongroup.options" :key="index2" :value="option"> {{option.text}} </option>
                    </optgroup>
                </select>
            </div>
          </div>
          <FormItem id="ptypexyz" :label="$t('operations.bl_payment')" type="buttongroup" v-model="payment_option" :options="paymentOptions" :required="true" />
        </div>
        <div class="modal-footer d-flex">
          <button type="button" class="btn btn-success" @click.prevent="acceptQuote" :disabled="!option_carrier_id || !payment_option">{{$t('sales.save_accept')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props: ['popupIsOpen', 'forwardingOptions'],
        name: 'QuoteAcceptPopup',
        data() {
            return {
                option_carrier_id: null,
                payment_option: null,
                vesselName: '-',
                paymentOptions: [{id: 'PREPAID', name: this.$t('operations.prepaid')}, {id: 'COLLECT', name: this.$t('operations.collect')}]
            }
           
        },
        methods: {
            acceptQuote(){
                this.$parent.quotation.option_carrier_id = this.option_carrier_id;
                this.$parent.quotation.payment_option = this.payment_option;
                this.$parent.$parent.$parent.$parent.saveEditJob();
                this.$parent.acceptPopupOpen = false;
            },
            reset(){
                this.option_carrier_id = null;
                this.payment_option = null;
                this.vesselName = '-';
            },
            setVesselName(){
              let found = false;
              if(this.option_carrier_id){
                this.forwardingOptions.forEach(optiongroup => {
                  optiongroup.options.forEach(option => {
                    console.log(option, this.option_carrier_id);
                    if(option == this.option_carrier_id){
                      this.vesselName = option.vessel;
                      found = true;
                    }
                  });
                });
              }
              if(!found){
                this.vesselName = '-';
              }
            }
        }
    }
</script>