<template>
<div class="tab-pane fade" id="freight" role="tabpanel" aria-labelledby="freight-tab">
    <!--- begin pre_transport  -->
    <template v-if="pretransport !== null">
        <QuotationTransportFormComponent
        :initialTransportLines="pretransport" :transportType="'pre'" 
        :currencies="currencies"
        :pols="pol"
        v-on:addTransportLine="$parent.addTransportLine" v-on:deleteTransportLine="$parent.deleteTransportLine">
        </QuotationTransportFormComponent>
    </template>         
    <!--- end pre_transport -->

    <!--- begin seafreight_transport  -->
    <h5 class="mt-4 mb-3 fw-bold">{{$t('sales.unit_details')}}</h5>

    <template v-for="(seatransportline,index) in thisquotationseatransportlines" :key="seatransportline.uuid">
    <template v-if="seatransportline && !seatransportline.is_surcharge && !isInSurcharges(seatransportline.description)">
        <div :class="'row transport-unit-table '+ (index > 0 ? 'barren' : '')">
            <label class="col-sm-xs col-form-label-sm required-label"> {{$t('form.qt')}} </label>
            <div class="col-sm-2 col-form-label-sm">
                <label class="required-label"> {{$tc('navigation.commodities', 1)}} </label>
                <span class="make-model-switch float-end" @click="setCommodityMode(seatransportline, index)">{{ seatransportline.should_show ? 'Show preferred' : 'Show all' }}</span>
            </div>
            <label class="col-sm-xs col-form-label-sm  required-label"> {{$t('form.new')}} </label>
            <div v-show="seatransportline.description != null" class="col-sm-3 col-form-label-sm"> {{$t('form.description')}}
                <span class="make-model-switch" @click="setMakeModel(true, seatransportline)">{{ $t('sales.or_make_model') }}</span>
            </div>
            <div v-show="seatransportline.description == null" class="col-sm-1p5 col-form-label-sm"> {{$t('navigation.makes')}}
                <span class="make-model-switch" @click="setMakeModel(false, seatransportline)">{{ $t('sales.or_desc') }}</span>
                <button class="btn btn-success add-makemodel ms-1" type="button" @click="showDialog('make', seatransportline)" v-show="!seatransportline.unit_make_id">+</button>
            </div>
            <div v-show="seatransportline.description == null" class="col-sm-1p5 col-form-label-sm"> 
                {{$t('navigation.models')}} 
                <button class="btn btn-success add-makemodel ms-2" type="button" @click="showDialog('model', seatransportline)" v-show="seatransportline.unit_make_id && !seatransportline.unit_model_id">+</button>
            </div>
            <label :class="'col-sm-' + (licensePlateRequired ? '1p5' : '3') + ' col-form-label-sm'"> {{ $t('sales.vin') }} </label>
            <label v-show="licensePlateRequired" class="col-sm-1p5 col-form-label-sm"> {{ $t('sales.license_plate') }} </label>
            <label class="col-sm-1 col-form-label-sm"> {{$t('form.length')}} </label>
            <label class="col-sm-1 col-form-label-sm"> {{$t('form.width')}} </label> 
            <label class="col-sm-1 col-form-label-sm"> {{$t('form.height')}} </label> 
            <label class="col-sm-1 col-form-label-sm"> {{$t('form.weight')}} </label> 
        </div>
        <div class="row transport-unit-table" :id="'collapse-'+index">
            <div class="col-sm-xs">
                <input class="form-control form-control-sm required-field" v-model="seatransportline.quantity" type="number" :id="'quantity-field-'+index" @change="getAllTotals(true); emitOnChange(); devalidate($event)">   
            </div>
            <div class="col-sm-2">
                <select v-model="seatransportline.commodity_id" @change="prefillTariffs(index, $event, true); emitOnChange(); devalidate($event); setSelection($event)"
                        class="form-select form-select-sm commodity_id required-field" @focus="setSelection($event, true)" @blur="setSelection($event)" @mousedown="setSelection($event, true, true)">
                    <option value=''>{{$t('form.select')}}</option>
                    <template v-if="commodities.preferred.length != 0 && !seatransportline.should_show"> <optgroup v-for="(list, name) in commodities.preferred" :key="name" :label="name">
                        <option v-for="commodity in list" :key="commodity.id" :value="commodity.id" @mouseenter="showConditionals($event, commodity)" @mouseleave="hideConditionals($event, commodity)"> {{commodity.name}} </option>
                    </optgroup> </template>
                    <option v-else v-for="commodity in commodities.all" :key="commodity.id" :value="commodity.id"> {{commodity.name}} </option>
                </select>
                <div class="popover-bubble bubble-commodity"></div>
            </div>
            <div class="col-sm-xs">
                <FormItem v-model="seatransportline.condition_id" type="slider" :options="[1,2]" @onchange="prefillTariffs(index, $event)" />
            </div>
            <div v-if="seatransportline.description != null" class="col-sm-3">
                <input class="form-control form-control-sm" v-model="seatransportline.description">
            </div>
            <template v-else>
                <div class="col-sm-1p5">
                    <FormItem :id="'make-'+index" v-model="seatransportline.unit_make_id" type="livesearch" :service="makeService" @onchange="(i, n) => {seatransportline.unit_make_name = n; seatransportline.unit_model_id = null}"
                              :content="seatransportline.make ? seatransportline.make.name : ''" :disabled="!seatransportline.commodity_id" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'model-'+index" v-model="seatransportline.unit_model_id" type="livesearch" :service="modelService" @onchange="(i, n) => {seatransportline.unit_model_name = n}"
                              :content="seatransportline.model ? seatransportline.model.name : ''" :disabled="!seatransportline.unit_make_id || !seatransportline.commodity_id"
                              :extraOptions="{'make_id': seatransportline.unit_make_id, 'comm_id': seatransportline.commodity_id}" />
                </div>
            </template>
            <div :class="'col-sm-' + (licensePlateRequired ? '1p5' : '3')">
                <input v-if="seatransportline.quantity <= 1" class="form-control form-control-sm chassis-nr license-plate" v-model="seatransportline.chassis_number" @keydown="licensePlateAlterInput" 
                      @change="toUpper($event.target, seatransportline); checkForDupes(seatransportline)" :style="{'backgroundColor': checkChassisNr(seatransportline.chassis_number)}" :data-id="index">
                <textarea v-else :class="'form-control form-control-sm license-plate '+(licensePlateRequired ? 'lp-yes' : 'lp-no')" v-model="seatransportline.chassis_number" @keydown="licensePlateAlterInput" @change="toUpper($event.target, seatransportline); checkForDupes(seatransportline)"></textarea>
                <div class="text-danger col-form-label-sm" v-if="seatransportline.duplicate"><i class="fa fa-warning"></i>&ensp;{{$t('errors.duplicate_in_db', ['VIN', seatransportline.duplicate.by])}}</div>
            </div>
            <div v-if="licensePlateRequired" class="col-sm-1p5">
                <input v-if="seatransportline.quantity <= 1" class="form-control form-control-sm license-plate" v-model="seatransportline.license_plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target)">
                <textarea v-else :class="'form-control form-control-sm license-plate '+(licensePlateRequired ? 'lp-yes' : 'lp-no')" v-model="seatransportline.license_plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target)"></textarea>
            </div>
            <div class="col-sm-1 input-group">
                <input class="form-control form-control-sm measure-field" type="number" :id="'length-field-'+index" v-model="seatransportline.length" @keydown="preventNonNumbers($event, false)" @change="getAllTotals(); setTariffsWithConditions(seatransportline, index)">
                <span class="input-group-text">cm</span>
            </div>
            <div class="col-sm-1 input-group">
                <input class="form-control form-control-sm measure-field" type="number" :id="'width-field-'+index" v-model="seatransportline.width" @keydown="preventNonNumbers($event, false)" @change="getAllTotals(); setTariffsWithConditions(seatransportline, index)">
                <span class="input-group-text">cm</span>
            </div> 
            <div class="col-sm-1 input-group">
                <input class="form-control form-control-sm measure-field" type="number" :id="'height-field-'+index" v-model="seatransportline.height" @keydown="preventNonNumbers($event, false)" @change="getAllTotals(); setTariffsWithConditions(seatransportline, index)">
                <span class="input-group-text">cm</span>
            </div> 
            <div class="col-sm-1 input-group">
                <input class="form-control form-control-sm measure-field" type="number" :id="'weight-field-'+index" v-model="seatransportline.weight" @keydown="preventNonNumbers($event, false)" @change="getAllTotals(); setTariffsWithConditions(seatransportline, index)">
                <span class="input-group-text">kg</span>
            </div>
            <div class="col">
                <button class="btn btn-sm float-right" type="button" title="Click to delete item" @click="deleteSeaTransportline(index)"><i class="far fa-trash-alt text-danger"></i></button>
            </div>
        </div>
        <div class="row transport-unit-table">
            <div class="col-auto d-flex gap-3" style="width: 70.3% !important">
                <div>
                    <FormItem :id="'track-'+index" v-model="seatransportline.tracked" type="checkbox" label="Tracked" @onchange="setTrackedTowed(seatransportline, index, 'tracked')" />
                </div>
                <div>
                    <FormItem :id="'towed-'+index" v-model="seatransportline.towed" type="checkbox" label="Towed" @onchange="setTrackedTowed(seatransportline, index, 'towed')" />
                </div>
                <div v-for="box in seatransportline.checkboxes" :key="box.id">
                    <FormItem :id="'xs'+box.id+'-'+index" v-model="box.should_show" type="checkbox" :label="box.label" @onchange="setTrackedTowed(seatransportline, index, box.name, box)" />
                </div>
            </div>
            <div class="col-sm-auto" style="min-width: 36px">
                <template v-if="getQuoteLineRel(seatransportline, 'contract_id')">
                    <a class="side-cts-button popover-trigger" :class="getQuoteLineRel(seatransportline, 'contract_id').includes('(') ? 'bg-warning' : 'bg-success text-white'" 
                       target="_blank" :href="'./contracts#'+getQuoteLineRel(seatransportline, 'contract_id', true)">c</a>
                    <div class="popover-bubble">{{$t('sales.rates_from', [getQuoteLineRel(seatransportline, 'contract_id')])}}</div>
                </template>
                <template v-else-if="getQuoteLineRel(seatransportline, 'tariff_id')">
                    <a class="side-cts-button popover-trigger" :class="getQuoteLineRel(seatransportline, 'tariff_id').includes('(') ? 'bg-warning' : ''" 
                       target="_blank" :href="'./tariffs#'+getQuoteLineRel(seatransportline, 'tariff_id', true)">t</a>
                    <div class="popover-bubble">{{$t('sales.rates_from', [getQuoteLineRel(seatransportline, 'tariff_id')])}}</div>
                </template>
                <template v-else-if="seatransportline.suggestedAlternative">
                    <div class="side-cts-button popover-trigger ps-0 text-center"><i class="fa fa-triangle-exclamation text-danger"></i></div>
                    <div class="popover-bubble bubble-xl"> {{$t('sales.cond_not_met')}}:<br /><span v-html="seatransportline.suggestedAlternative"></span> </div>
                </template>
            </div>
            <div class="col-sm-1 input-group">
                <div class="input-group-text w-75">{{ formatNumber((seatransportline.length * seatransportline.width * seatransportline.height) / 1000000) }}</div>
                <span class="input-group-text w-25">m³</span>
            </div>
            <div class="col-sm-auto">
                <button class="btn btn-sm btn-primary" type="button" title="Add stacked unit" @click="addStackedUnit(index)">
                    <i class="fa fa-plus"></i>&ensp;{{$t('form.add', ['stacked'])}}
                </button>
            </div>
            <div class="col-sm-auto">
                <button class="btn btn-sm btn-primary" type="button" title="Add stacked unit" @click="addStackedUnit(index, true)" v-if="!seatransportline.stacked_units.find(ju => ju.stacking_type == 'connected')">
                    <i class="fa fa-plus"></i>&ensp;{{$t('form.add', ['connected'])}}
                </button>
            </div>
        </div>
        <div class="border-left">
        <div class="row transport-unit-table" :style="{height : unit.quantity > 1 ? '4rem' : 'auto'}" v-for="(unit, uindex) in seatransportline.stacked_units" :key="unit.order_by">
            <div class="col-sm-auto" style="width: 0.8%"></div>
            <div class="col-sm-auto" style="width: 3.8%">
                <input class="form-control form-control-sm required-field" v-model="unit.quantity" type="number" @change="emitOnChange(); devalidate($event)">   
            </div>
            <div class="col-sm-2">
                <select v-model="unit.commodity_id" @change="devalidate($event)" class="form-select form-select-sm commodity_id required-field" id="comms_id">
                    <option value=''>{{$t('form.select')}}</option>
                    <option v-for="commodity in commodities.all" :key="commodity.id" :value="commodity.id"> {{commodity.name}} </option>
                </select>
            </div>
            <div class="col-sm-xs">
                <FormItem :id="'cond-'+index+'-'+uindex" v-model="unit.condition_id" type="slider" :options="[1,2]" />
            </div>
            <div v-if="seatransportline.description != null" class="col-sm-3">
                <input class="form-control form-control-sm" v-model="unit.description">
            </div>
            <template v-else>
                <div class="col-sm-1p5">
                    <FormItem :id="'make-'+index+'-'+uindex" v-model="unit.unit_make_id" type="livesearch" :service="makeService" @onchange="(i, n) => {unit.unit_make_name = n; unit.unit_model_id = null}"
                            :content="unit.make ? unit.make.name : ''" :disabled="!unit.commodity_id" />
                </div>
                <div class="col-sm-1p5">
                    <FormItem :id="'model-'+index+'-'+uindex" v-model="unit.unit_model_id" type="livesearch" :service="modelService" @onchange="(i, n) => {unit.unit_model_name = n}"
                            :content="unit.model ? unit.model.name : ''" :disabled="!unit.unit_make_id || !unit.commodity_id"
                            :extraOptions="{'make_id': unit.unit_make_id, 'comm_id': unit.commodity_id}" />
                </div>
            </template>
            <div :class="'col-sm-' + (licensePlateRequired ? '1p5' : '3')">
                <input v-if="unit.quantity <= 1" class="form-control form-control-sm license-plate chassis-nr" v-model="unit.chassis_number"
                    @keydown="licensePlateAlterInput" @change="toUpper($event.target, unit)" :style="{'backgroundColor': checkChassisNr(unit.chassis_number)}">
                <textarea v-else :class="'form-control form-control-sm license-plate '+(licensePlateRequired ? 'lp-yes' : 'lp-no')" v-model="unit.chassis_number" @keydown="licensePlateAlterInput" @change="toUpper($event.target, unit)"></textarea>
            </div>
            <div v-if="licensePlateRequired" class="col-sm-1p5">
                <input v-if="unit.quantity <= 1" class="form-control form-control-sm license-plate" v-model="unit.license_plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target)">
                <textarea v-else :class="'form-control form-control-sm license-plate '+(licensePlateRequired ? 'lp-yes' : 'lp-no')" v-model="unit.license_plate" @keydown="licensePlateAlterInput" @change="toUpper($event.target)"></textarea>
            </div>
            <div class="col-1 text-capitalize">
                <i class="fa" :class="{'fa-layer-group': unit.stacking_type == 'stacked', 'fa-link': unit.stacking_type == 'connected'}"></i>&nbsp;{{unit.stacking_type}}
            </div>
            <div class="col">
                <button class="btn btn-sm float-right" type="button" title="Click to delete item" @click="deleteStackedUnit(seatransportline, uindex)"><i class="far fa-trash-alt text-danger"></i></button>
            </div>
        </div>
        </div>
    </template>
    </template>

    <div class="row mt-4 justify-content-center">
        <a class="btn btn-sm btn-primary w-25" title="Click to add item"  @click="addSeaTransportline">
            <span class="fa fa-plus"></span> &nbsp;{{$t('form.add', [$tc('navigation.units', 1)])}}
        </a>
    </div>
            
    <!--- end seafreight_transport -->
    <!--- begin post_transport  --> 
    <template v-if="posttransport !== null">
        <QuotationTransportFormComponent
        :initialTransportLines="posttransport" :transportType="'post'" 
        :currencies="currencies"
        v-on:addTransportLine="$parent.addTransportLine" v-on:deleteTransportLine="$parent.deleteTransportLine">
        </QuotationTransportFormComponent>
    </template>      
    <!--- end post_transport -->
</div>
<!--- begin seafreight_rates --> 
<div class="tab-pane fade" id="rates" role="tabpanel" aria-labelledby="rates-tab">
<section id="q-fragment" class="p-1 document-fragment">
    <h5 class="fw-bold mb-0">{{$t('sales.sea_freight')}}</h5>

    <div v-for="(carrier, cindex) in masterRates" :key="cindex" :id="'carrier-table-'+cindex">
        <div class="row mt-3 pt-2">
            <div class="col-sm-auto fw-bold display-name" style="width: 32%"> {{getDisplayName(cindex, false, carrier.name, carrier.id)}} </div>
            <div class="col-sm-2"> {{$tc('sales.buying_rates', 1)}} </div>
            <div class="col-sm-1"> {{$t('form.per')}} </div>
            <div class="col-sm-2"> {{$tc('sales.sales_rates', 1)}} </div>
            <div class="col-sm-1"> {{$t('form.per')}} </div>
        </div>
        <div v-for="(seatransportline,index) in thisquotationseatransportlines" :key="index" class="mt-1 row" :data-required="forceGet(seatransportline, cindex).show" :data-index="index" :data-surcharge="forceGet(seatransportline, cindex).surcharge_id != undefined">
        
            <!-- Unit, not a surcharge -->
            <template v-if="seatransportline && !seatransportline.is_surcharge && forceGet(seatransportline, cindex).surcharge_id == undefined">
                <div class="col-sm-auto" style="width: 32%"> {{getDescString(seatransportline)}} </div>
                <div class="col-sm-1">
                    <input class="form-control form-control-sm number-format purchase-price" v-model="forceGet(seatransportline, cindex).buying_rate" @keydown="preventNonNumbers"
                    @input="devalidate($event)" :data-index="index" required="">  
                </div>
                <div class="col-sm-1">
                    <select v-model="forceGet(seatransportline, cindex).buying_rate_currency_id" class="form-select form-select-sm" @change="devalidate($event)" required="">
                        <option value=''>{{$t('form.select')}}</option>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                    </select>
                </div>
                <div class="col-sm-1">
                    <select v-model="forceGet(seatransportline, cindex).buying_rate_calculation_unit_id" class="form-select form-select-sm" @change="getAllTotals(); devalidate($event)" required="">
                        <option :value="null">{{$t('form.select')}}</option>
                        <option v-for="calc_unit in calc_units_no_percentage" :key="calc_unit.id" :value="calc_unit.id" > {{calc_unit.name}} </option>
                    </select>
                </div>
                <div class="col-sm-1">
                    <input class="form-control form-control-sm number-format sales-prices" v-model="forceGet(seatransportline, cindex).sales_rate" @keydown="preventNonNumbers" required=""
                    @input="autoFillSalesRates(index, 'sales_rate', $event); devalidate($event)" :data-index="index">
                </div>
                <div class="col-sm-1">
                    <select v-model="forceGet(seatransportline, cindex).sales_rate_currency_id"  class="form-select form-select-sm" required=""
                            @change="autoFillSalesRates(index, 'sales_rate_currency_id', $event); devalidate($event)">
                        <option value=''>{{$t('form.select')}}</option>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                    </select>
                </div>
                <div class="col-sm-1">
                    <select v-model="forceGet(seatransportline, cindex).sales_rate_calculation_unit_id" class="form-select form-select-sm" required=""
                            @change="getAllTotals(); autoFillSalesRates(index, 'sales_rate_calculation_unit_id', $event); devalidate($event)">
                        <option :value="null">{{$t('form.select')}}</option>
                        <option v-for="calc_unit in calc_units_no_percentage" :key="calc_unit.id" :value="calc_unit.id" > {{calc_unit.name}} </option>
                    </select>
                </div>
                <div class="col-sm-auto p-0" style="width: 2rem">
                    <template v-if="forceGet(seatransportline, cindex).contract_id != undefined || forceGet(seatransportline, cindex).tariff_id != undefined">
                        <a v-if="forceGet(seatransportline, cindex).contract_id != undefined" class="side-cts-button popover-trigger" :class="setInfoBlurbClasses(forceGet(seatransportline, cindex))" 
                           target="blank" :href="'./contracts#' + forceGet(seatransportline, cindex).contract_id">c</a>
                        <a v-else class="side-cts-button popover-trigger" :class="setInfoBlurbClasses(forceGet(seatransportline, cindex))" target="blank" :href="'./tariffs#' + forceGet(seatransportline, cindex).tariff_id">t</a>
                        <div class="popover-bubble">{{$t('sales.rates_from', [forceGet(seatransportline, cindex).tariffOrContractNr])}}</div>
                    </template>
                </div>
                <div style="width: 7%" v-html="checkRates(forceGet(seatransportline, cindex), cindex, index, seatransportline)"></div>
                <div class="col-sm-auto p-0">
                    <button class="btn btn-sm btn-primary float-right rounded" type="button" title="Click to add surcharge" @click="addManualSurcharge(carrier, index)">
                        +&nbsp;{{$t('form.add', [$t('navigation.surcharges')])}}
                    </button>
                </div>
            </template>

            <!-- Manually added surcharge -->
            <template v-else-if="seatransportline && forceGet(seatransportline, cindex).surcharge_id == undefined && shouldShowSurcharge(seatransportline, cindex, index, true)">
                <div class="col-sm-2"> {{$t('navigation.surcharges')}} </div>
                <div class="col-sm-auto p-0" style="width: 14pt">
                    <input type="checkbox" v-model="forceGet(seatransportline, cindex).should_show" class="form-check-input mt-2" :true-value="1" :false-value="0">
                </div>
                <div class="col-sm-1" style="width: 14%">
                    <select v-model="seatransportline.description" class="form-select form-select-sm" :id="'stype_'+cindex" required="">
                        <option v-if="forceGet(seatransportline, cindex).surcharge_id == undefined" :value="null">{{$t('form.select')}}</option>
                        <option v-for="stype in filteredSurchargeTypes" :key="stype.id" :value="stype.code"> {{stype.code}} </option>
                    </select>
                </div>
                <div class="col-sm-1">
                    <input class="form-control form-control-sm number-format purchase-price surcharge" v-model="forceGet(seatransportline, cindex).buying_rate" @keydown="preventNonNumbers($event, true, true)" 
                    @input="devalidate($event)" @change="setZeroOnEmpty($event, forceGet(seatransportline, cindex))" :data-index="cindex" required="">  
                </div>
                <div class="col-sm-1">
                    <select v-model="forceGet(seatransportline, cindex).buying_rate_currency_id" class="form-select form-select-sm" @change="devalidate($event)"
                                    v-if="forceGet(seatransportline, cindex).buying_rate_calculation_unit_id != 5" required="">
                        <option value=''>{{$t('form.select')}}</option>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                    </select>
                </div>
                <div class="col-sm-1">
                    <select v-model="forceGet(seatransportline, cindex).buying_rate_calculation_unit_id" class="form-select form-select-sm" @change="devalidate($event)" required="">
                        <option :value="null">{{$t('form.select')}}</option>
                        <option v-for="calc_unit in calc_units" :key="calc_unit.id" :value="calc_unit.id" > {{calc_unit.name}} </option>
                    </select>
                </div>
                <div class="col-sm-3 pt-1" v-if="isPartOfLumpsum(index, cindex)">
                    {{ $t('sales.included_in_lump_sum') }}
                </div>
                <template v-else>
                    <div class="col-sm-1">
                        <input class="form-control form-control-sm number-format sales-prices surcharge" v-model="forceGet(seatransportline, cindex).sales_rate" @keydown="preventNonNumbers($event, true, true)" 
                        @input="devalidate($event)" @change="setZeroOnEmpty($event, forceGet(seatransportline, cindex))" :data-index="cindex" required="">
                    </div>
                    <div class="col-sm-1">
                        <select v-model="forceGet(seatransportline, cindex).sales_rate_currency_id"  class="form-select form-select-sm" @change="devalidate($event)"
                                        v-if="forceGet(seatransportline, cindex).sales_rate_calculation_unit_id != 5" required="">
                            <option value=''>{{$t('form.select')}}</option>
                            <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                        </select>
                    </div>
                    <div class="col-sm-1">
                        <select v-model="forceGet(seatransportline, cindex).sales_rate_calculation_unit_id" class="form-select form-select-sm" @change="devalidate($event)" required="">
                            <option :value="null">{{$t('form.select')}}</option>
                            <option v-for="calc_unit in calc_units" :key="calc_unit.id" :value="calc_unit.id" > {{calc_unit.name}} </option>
                        </select>
                    </div>
                </template>
                <div class="col-sm-auto p-0" style="width: 2rem">
                    <div class="side-cts-button">s</div>
                </div>
                <div style="width: 7%" v-html="checkRates(forceGet(seatransportline, cindex), cindex, index, seatransportline)"></div>
                <div class="col-sm-auto p-0" v-show="forceGet(seatransportline, cindex).surcharge_id == undefined">
                    <button class="btn btn-sm float-right" type="button" title="Click to delete item" @click="deleteSeaTransportline(index, true)"><i class="far fa-trash-alt text-danger"></i></button>
                </div>
            </template>

            <!-- Automatically added surcharge -->
            <template v-else-if="seatransportline && shouldShowSurcharge(seatransportline, cindex, index)">
                <div class="col-sm-2"> {{$t('navigation.surcharges')}} </div>
                <div class="col-sm-auto p-0" style="width: 14pt">
                    <input :id="cindex+'-'+seatransportline.description" type="checkbox" v-model="forceGet(seatransportline, cindex).should_show" class="form-check-input mt-2" :true-value="1" :false-value="0" :disabled="seatransportline.optional">
                </div>
                <div v-if="seatransportline.optional" class="col-sm-1 form-select-sm" style="width: 14%; padding-left: 19px"><label :for="cindex+'-'+seatransportline.description">{{ seatransportline.description }}</label></div>
                <div v-else class="col-sm-1" style="width: 14%">
                    <label class="form-select-sm" :for="cindex+'-'+seatransportline.description">{{seatransportline.description}}</label>
                </div>
                <div class="col-sm-1">
                    <div class="form-control form-control-sm number-format purchase-price surcharge disabled" :data-index="cindex">{{formatCurrency(forceGet(seatransportline, cindex).buying_rate).replace(',00', '')}}</div>  
                </div>
                <div class="col-sm-1">
                    <select v-model="forceGet(seatransportline, cindex).buying_rate_currency_id" class="form-select form-select-sm" v-if="forceGet(seatransportline, cindex).buying_rate_calculation_unit_id != 5" disabled="">
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                    </select>
                </div>
                <div class="col-sm-1">
                    <select v-model="forceGet(seatransportline, cindex).buying_rate_calculation_unit_id" class="form-select form-select-sm" disabled="">
                        <option :value="null">{{$t('form.select')}}</option>
                        <option v-for="calc_unit in calc_units" :key="calc_unit.id" :value="calc_unit.id" > {{calc_unit.name}} </option>
                    </select>
                </div>
                <div class="col-sm-3 pt-1" v-if="isPartOfLumpsum(index, cindex)">
                    {{ $t('sales.included_in_lump_sum') }}
                </div>
                <template v-else>
                    <div class="col-sm-1">
                        <div class="form-control form-control-sm number-format sales-prices surcharge disabled" :data-index="cindex">{{formatCurrency(forceGet(seatransportline, cindex).sales_rate).replace(',00', '')}}</div>
                    </div>
                    <div class="col-sm-1">
                        <select v-model="forceGet(seatransportline, cindex).sales_rate_currency_id" class="form-select form-select-sm" v-if="forceGet(seatransportline, cindex).sales_rate_calculation_unit_id != 5" disabled="">
                            <option v-for="currency in currencies" :key="currency.id" :value="currency.id"> {{currency.code}} </option>
                        </select>
                    </div>
                    <div class="col-sm-1">
                        <select v-model="forceGet(seatransportline, cindex).sales_rate_calculation_unit_id" class="form-select form-select-sm" disabled="">
                            <option v-for="calc_unit in calc_units" :key="calc_unit.id" :value="calc_unit.id" > {{calc_unit.name}} </option>
                        </select>
                    </div>
                </template>
                <div class="col-sm-auto p-0" style="width: 2rem">
                    <a class="side-cts-button" target="_blank" :href="'./surcharges#' + forceGet(seatransportline, cindex).surcharge_id">s</a>
                </div>
                <div style="width: 7%" v-html="checkRates(forceGet(seatransportline, cindex), cindex, index, seatransportline)"></div>
            </template>
        </div>

        </div>
    </section>
</div>
<!--- end seafreight_rates -->
 
<dialog id="makemodel-dialog" style="width: 600px; border: none; background-color: transparent">
    <form method="dialog" class="modal-content w-100">
        <div class="modal-header w-100 py-3">
            <h5 style="font-size: 1.2rem !important" class="ps-2 mb-0" id="detailsHeaderAbove">{{$t('form.add', [$tc(showMakeForm ? 'navigation.makes' : 'navigation.models', 1)])}}</h5>
            <button id="fakeCloseBtn3" type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
        </div>
        <div class="p-4" style="overflow-y: auto" :class="{'d-none': !showMakeForm}"> <MakeForm ref="makeForm" /> </div>
        <div class="p-4" style="overflow-y: auto" :class="{'d-none': !showModelForm}"> <ModelForm ref="modelForm" /> </div>
        <div class="d-flex justify-content-end gap-3 p-3 border-top">
            <FormItem :options="{send: true}" startTag="fa fa-save" :service="saveMakeModel" type="download" :label="$t('form.save')" />
        </div>
    </form>
</dialog>

</template>

<script>
import contractService from "@/services/ContractService";
import surchargetypeService from "@/services/SurchargetypeService";
import makeService from "@/services/MakeService";
import modelService from "@/services/ModelService";
import QuotationTransportFormComponent from './QuotationTransportFormComponent';
import MakeForm from '@/components/make/MakeForm';
import ModelForm from '@/components/model/ModelForm';
import JobUnitService from '@/services/JobUnitService';
import store from '@/store/user';
export default {
    
    name: 'QuaotationSeatransportFormComponent',
    props: ['initialSeaTransportLines','commodities','currencies','selCurrency','pol','pod','masterRates','isForwarding', 
            'carriers', 'customer', 'surcharges', 'pretransport', 'posttransport', 'licensePlateRequired', 'carrierPols'],
    components: {
        QuotationTransportFormComponent,
        MakeForm,
        ModelForm
    },
    emits: ['disableNext', 'enableNext'],
    computed: {
        thisquotationseatransportlines: {
            get() {
                return this.initialSeaTransportLines
            }
        }
    },
    data() {
        return {
            selectedCurrency: {'id': 0, 'code': '???'},
            surchargeTypes: [],
            filteredSurchargeTypes: [],
            trackedCode: "Tracking Surcharge",
            towedCode: "Towing Surcharge",
            makes: [],
            models: [],
            hscodes: [],
            colours: [],
            calc_units_no_percentage: [],
            storedSurcharges: {},
            storedTariffs: {},
            conditionParams: ['', [this.$t('form.length'), 'cm'], [this.$t('form.width'), 'cm'], [this.$t('form.height'), 'cm'], [this.$t('form.weight'), 'kg'], 'm²', 'm³', 'w/m'],
            calc_units: [],
            makeService: makeService,
            modelService: modelService,
            showMakeForm: false,
            showModelForm: false,
            formRef: {},
            storedData: null,
            dataItemsLoaded: 0,
            dataItemsToLoad: 3
        }
    },
    methods: {
        addSeaTransportline(e, addFirstItem = false) {
            let newindex = 0;
            this.thisquotationseatransportlines.forEach(u => newindex = u.uuid > newindex ? u.uuid : newindex);
            newindex++;
            this.thisquotationseatransportlines.push({
                'quantity': '1',
                'description': null,
                'chassis_number': null,
                'length': null,
                'width': null,
                'height': null,
                'weight': null,
                'unit_make_id': null,
                'unit_model_id': null,
                'construction_year': null,
                'colour_id': null,
                'hs_code': null,
                'license_plate': null,
                'sales_currency_id': addFirstItem ? '1' : window.defaultCurrency,
                'sales_price': '0',
                'buying_currency_id': addFirstItem ? '1' : window.defaultCurrency,
                'buying_price': '0',
                'commodity_id': '',
                'condition_id': '2',
                'contract_id': null,
                'tariff_id': null,
                'surcharge_id': null,
                'is_surcharge': false,
                'towed': 0,
                'tracked': 0,
                'checkboxes': [],
                'uuid': addFirstItem ? 0 : newindex,
                'stacked_units': [],
                'quote_line_carriers': addFirstItem ? [] : this.hardCopy(this.masterRates)
            });
            this.$emit('disableNext');
        },
        deleteSeaTransportline(index, isManualSurcharge = false) {
            if(confirm("Are you sure you want to delete this " + (isManualSurcharge ? "surcharge" : "freight") + "?")) {
                let deleteCount = 1;
                const size = this.thisquotationseatransportlines.length;
                if(!isManualSurcharge){
                    for(let i = index + 1; i < size; i++){
                        if(this.thisquotationseatransportlines[i] && this.thisquotationseatransportlines[i].surcharge_id != null){
                            deleteCount++;
                        }
                        else{
                            break;
                        }
                    }
                }
                this.thisquotationseatransportlines.splice(index, deleteCount);
                this.$emit('enableNext');
            }
        },
        addStackedUnit(index, isConnected = false){
            let newindex = 0;
            this.thisquotationseatransportlines[index].stacked_units.forEach(su => newindex = su.order_by > newindex ? su.order_by : newindex);
            newindex++;
            this.thisquotationseatransportlines[index].stacked_units.push({
                'quantity': '1',
                'description': null,
                'chassis_number': null,
                'unit_make_id': null,
                'unit_model_id': null,
                'construction_year': null,
                'colour_id': null,
                'hs_code': null,
                'license_plate': null,
                'commodity_id': '',
                'condition_id': '2',
                'order_by': newindex,
                'stacking_type': isConnected ? 'connected' : 'stacked'
            });
            this.$emit('disableNext');
        },
        deleteStackedUnit(unit, index) {
            if(confirm("Are you sure you want to delete this freight?")) {
                unit.stacked_units.splice(index, 1);
                this.$emit('enableNext');
            }
        },

        setTrackedTowed(item, index, field, box = null){
            let i = index + 1;
            const length = this.thisquotationseatransportlines.length;
            while(i < length){
                if(!this.thisquotationseatransportlines[i] || (!this.thisquotationseatransportlines[i].is_surcharge && !this.thisquotationseatransportlines[i].surcharge_id)){
                    break;
                }
                if(field == 'tracked' && this.thisquotationseatransportlines[i].description == this.trackedCode){
                    this.thisquotationseatransportlines[i].quote_line_carriers.forEach(ql => ql.sales_rate ? (ql.should_show = item.tracked ? 1 : 0) : 0);
                    break;
                }
                if(field == 'towed' && this.thisquotationseatransportlines[i].description == this.towedCode){
                    this.thisquotationseatransportlines[i].quote_line_carriers.forEach(ql => ql.sales_rate ? (ql.should_show = item.towed ? 1 : 0) : 0);
                    break;
                }
                if(box && this.thisquotationseatransportlines[i].description == field){
                    this.thisquotationseatransportlines[i].quote_line_carriers.forEach(ql => ql.sales_rate ? (ql.should_show = box.should_show ? 1 : 0) : 0);
                }
                i++;
            }
        },
        checkTrackedTowed(index, field, extraOption = false){
            let i = index - 1;
            while(i >= 0){
                if(!this.thisquotationseatransportlines[i] || (!this.thisquotationseatransportlines[i].is_surcharge && !this.thisquotationseatransportlines[i].surcharge_id)){
                    return extraOption ? (this.thisquotationseatransportlines[i].checkboxes.find(b => b.name == field && b.should_show) ? 1 : 0) : this.thisquotationseatransportlines[i][field];
                }
                i--;
            }
        },

        setCalcUnits(e){
            this.calc_units = e;
            this.calc_units_no_percentage = e.filter(unit => unit.surcharge_only == false);
        },

        getDisplayName(index, getCarrierToo = false, carrierName ='', carrierId = null){
            try{
                let cname = carrierName;
                if(!cname)
                    cname = document.querySelectorAll('#carrier_id_wrapper + div span')[index].innerHTML.replace('amp;', '');
                let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                let result = this.masterRates.length == 1 ? '' : 'Option ' + alphabet[index % 26] + ': ';
                const mr = carrierId ? this.masterRates.find(rate => rate.carrier_id == carrierId) : this.masterRates[index];
                const carrier_id = mr.carrier_id ? mr.carrier_id : mr.id;
                const pol = this.pol.find(p => p.port_id == (mr.port_of_loading_id ? mr.port_of_loading_id : this.pol[0].port_id));
                result += (pol ? pol.name : '');
                result += (' (' + ((cname.length > 20) ? (cname.slice(0, 20) + '...') : cname) + ')');
                if(getCarrierToo){
                    if(this.$parent.quoteAlreadyAccepted){
                        let voyage = this.$parent.quotation.quote_routes.find(qr => qr.preferred);
                        return {'carrier': carrier_id, 'text': result + ', ' + (voyage ? voyage.quote_route_segments.map(seg => seg.name).join('-') : "no voyage preference")};
                    }
                    let filteredVoyages = this.$parent.quotation.quote_routes.filter(qr => qr.origin_port_id == pol.port_id && qr.quote_route_segments[0].carrier_id == carrier_id);
                    let options = [{'carrier': carrier_id, 'port': pol.port_id, 'voyage_id': null, 'text': result + ", no voyage preference", vessel: '-'}];
                    filteredVoyages.forEach(fv => {
                        options.push({'carrier': carrier_id, 'port': pol.port_id, 'voyage_id': fv.voyage_id, 
                            'text': result + ', ' + fv.quote_route_segments.map(seg => seg.name).join('-'), vessel: fv.quote_route_segments.map(seg => seg.vessel).join(', ')});
                    })
                    return ({'text': result, 'options': options});
                }
                return result;
            }
            catch(e){
                console.log(e)
                return '';
            }
        },
        getDescString(item){
            let dString = "";
            try{
                let commodity = this.safeFind(this.commodities.all, "id", item.commodity_id, "name");
                let condition = item.condition_id === 1 ? 'new' : 'used';
                dString = item.quantity + " " + commodity + ", " + condition;
                if(item.description){
                    dString += (" (" + item.description + ")");
                }
                else if(item.unit_model_id){
                    const make = item.unit_make_name ? item.unit_make_name : (item.make ? item.make.name : false);
                    const model = item.unit_model_name ? item.unit_model_name : (item.model ? item.model.name : false);
                    if(make && model)
                        dString += (" (" + make + " " + model + ")");
                }
                return dString;
            }
            catch(e){
                console.log(e);
                return "";
            }
        },
        prefillTariffs(index, e, setMakeModelNull = false, autoUpdate = false){
            let item = this.thisquotationseatransportlines[index];
            if(setMakeModelNull){
                item.unit_make_id = null;
                item.unit_model_id = null;
                const el1 = document.querySelector("#make-" + index + "_wrapper > .simple-typeahead-reset");
                const el2 = document.querySelector("#model-" + index + "_wrapper > .simple-typeahead-reset");
                if(el1 && el2){
                    el1.click();
                    el2.click();
                }
            }
            let temp = {'buffer': this};
            item.suggestedAlternative = null;
            const contracts = this.$parent.contracts;
            item.quote_line_carriers.forEach(qlc => delete qlc.contract_id);
            if(contracts && contracts.length > 0){
                for(let contract of contracts){
                    if(contract.commodity_id == item.commodity_id){
                        if(contract.measurement_conditional_description){
                            item.mayHaveContract = true;
                            if(autoUpdate) { 
                                this.setTariffsWithConditions(item, index);
                            }
                        }
                        else{
                            this.addItemsInContract(contract, index);
                        }
                    }
                }
            }
            if(item.commodity_id && item.condition_id && this.pol != null && this.pod != null){
                let pols = this.pol.map(pol => (pol.port_id ? pol.port_id : pol.id));
                let fromOtherOffice = false;
                contractService.getTariffs( this.customer, item.commodity_id, item.condition_id, item.calculation_unit_id, pols, this.pod[0].id, this.carriers, this.$parent.quotation.valid_till, this.masterRates
                ).then(response => {
                    let tariff = response.data.tariff_items;
                    item.tariffsChecked = true;
                    item.allTariffs = {};
                    if(tariff == null){
                        item.quote_line_carriers.forEach(itemTariff => {
                            itemTariff.tariff_id = null;
                            if(itemTariff.contract_id) return;
                            itemTariff.tariffOrContractNr = '';
                        });
                        return;
                    }
                    for(let tindex in tariff){
                        if(!tariff[tindex]) 
                            continue;
                        for(let tariffCommodity of tariff[tindex].tariff_item_commodities){
                            for(let tariffCommodityCommodity of tariffCommodity.tariff_item_commodity_commodities){
                                if(tariffCommodityCommodity.tariff_item_commodity_measurement_conditionals.length){
                                    if(!item.allTariffs[tariffCommodityCommodity.commodity_id]) item.allTariffs[tariffCommodityCommodity.commodity_id] = [];
                                    let newItem = this.hardCopy(tariffCommodity);
                                    newItem.number = tariff[tindex].number;
                                    newItem.tariff_id = tariff[tindex].tariff_id;
                                    item.allTariffs[tariffCommodityCommodity.commodity_id].push(newItem);
                                }
                            }
                        }
                    }

                    let companyId = store.getters.getCompanyId;
                    item.quote_line_carriers.forEach(itemTariff => {
                        if(itemTariff.contract_id)
                            return;
                        let foundData = false;
                        let hasCondValues = false;
                        let lastcomm = {tariff_item_commodity_measurement_conditionals: []};
                        let thistariff = tariff.find(t => t && t.carrier_id == itemTariff.carrier_id && t.port_of_loading_id == itemTariff.port_of_loading_id);
                        let wrongConditionWarning = false;
                        if(thistariff){
                            for(let tariffCommodity of thistariff.tariff_item_commodities){
                                tariffCommodity.tariff = {start_date: thistariff.tariff.start_date, end_date: thistariff.tariff.end_date};
                                let maybeWrongCondition = false;
                                let thiscomm = tariffCommodity.tariff_item_commodity_commodities.find(comm => (comm.commodity_id == item.commodity_id && (comm.condition_id == item.condition_id || comm.condition_id === null)));
                                if(thiscomm == null){
                                    thiscomm = tariffCommodity.tariff_item_commodity_commodities.find(comm => comm.commodity_id == item.commodity_id);
                                    if(thiscomm == null) continue;
                                    maybeWrongCondition = true;
                                }
                                else{
                                    lastcomm = {...thiscomm};
                                }
                                if(thiscomm.tariff_item_commodity_measurement_conditionals.length > 0){
                                    if(!this.storedTariffs[item.commodity_id]) this.storedTariffs[item.commodity_id] = [];
                                    let newItem = this.hardCopy(tariffCommodity);
                                    newItem.number = thistariff.number;
                                    newItem.tariff_id = thistariff.tariff_id;
                                    newItem.carrier_id = itemTariff.carrier_id;
                                    newItem.port_of_loading_id = itemTariff.port_of_loading_id;
                                    newItem.office_creator_id = thistariff.tariff.office_creator_id;
                                    this.storedTariffs[item.commodity_id].push(newItem);
                                    itemTariff.mayHaveTariff = true;
                                    item.mayHaveTariff = true;
                                    let meetsConditions = this.checkConditions(thiscomm, item);
                                    if(!meetsConditions){
                                        if(meetsConditions !== null){
                                            hasCondValues = true;
                                        }
                                        continue;
                                    }
                                }
                                if(!foundData && !maybeWrongCondition){
                                    this.setTariffData(tariffCommodity, itemTariff, item, thistariff, companyId);
                                    fromOtherOffice = thistariff.tariff.office_creator_id;
                                    foundData = true;
                                }
                                else if(!foundData){
                                    wrongConditionWarning = true;
                                }
                            }
                        }
                        if(!foundData){
                            itemTariff.tariff_id = null;
                            itemTariff.tariffOrContractNr = '';
                            if(wrongConditionWarning){
                                item.suggestedAlternative = this.$t('form.condition') + ': ' + this.$t(item.condition_id == 1 ? 'form.used' : 'form.new');
                            }
                            else if(hasCondValues){
                                this.findAlternativeComm(item, lastcomm);
                            }
                        }
                    });
                }).catch(error => console.log('error', error)
                ).finally(() => {
                    if(e != undefined && !autoUpdate){
                        this.setSurcharges(item, index, this.getSurchargeList(index), fromOtherOffice);
                    }
                    temp.buffer.$nextTick( () => temp.buffer.getAllTotals() );
                });
            }
        },
        emitOnChange(){
            this.$emit('enableNext');
        },
        setSurcharges(quoteLine, qlIndex, surchargeList = false, officeId = false){
            let deleteIndexes = [];
            for(let thisIndex in this.thisquotationseatransportlines){
                if(thisIndex > qlIndex){
                    const line = this.thisquotationseatransportlines[thisIndex];
                    if(line.surcharge_id != null){
                        deleteIndexes.push(thisIndex);
                    }
                    else if(!line.is_surcharge){
                        break;
                    }
                }
            }
            for(let i = deleteIndexes.length - 1; i >= 0; i--){
                this.thisquotationseatransportlines.splice(deleteIndexes[i], 1);
            }
            let spliceIndex = (qlIndex + 1);
            const userOfficeId = officeId === false ? store.getters.getCompanyId : officeId;
            this.thisquotationseatransportlines[qlIndex].checkboxes = [];
            let alreadyDone = [];
            let rates = {};
            this.surcharges.forEach(surcharge => {
                if(surcharge.office_id != userOfficeId) return;
                let carrierIds = surcharge.carriers.map(c => Number(c.id));
                surcharge.items.forEach(item => {
                    let polIds = item.loading_ports.map(p => (p.id));
                    for(let realItem of item.commodities){
                        if(realItem.commodities.find(ri => (ri.id == quoteLine.commodity_id))){
                            realItem.surcharge_types.forEach(st => {
                                let addedChargeBuying = st.buying_rate;
                                let addedChargeSales = st.sales_rate;
                                if(st.surcharge_type.has_measurement_conditionals){
                                    let compareFrom = Number(st.condition_from);
                                    let compareEvery = Number(st.condition_every);
                                    let compareTo = this.getCompareTo(quoteLine, st.surcharge_type.measurement_conditional_id);
                                    if(compareTo < compareFrom) return;
                                    addedChargeBuying = Number(addedChargeBuying) * Math.ceil((compareTo - compareFrom) / compareEvery);
                                    addedChargeSales = Number(addedChargeSales) * Math.ceil((compareTo - compareFrom) / compareEvery);
                                }
                                let shouldShowFromEdit = 0;
                                let isInList = surchargeList ? true : false;
                                if(surchargeList){
                                    let listitem = surchargeList.find(carr => carr.description == st.surcharge_type.code);
                                    if(listitem) {
                                        listitem = listitem.quote_line_carriers.find(li => (li.should_show == 1 || li.should_show === true));
                                        shouldShowFromEdit = listitem ? 1 : 0;
                                    }
                                    else isInList = false;
                                }
                                if(st.surcharge_type.optional && st.surcharge_type.code != this.trackedCode && st.surcharge_type.code != this.towedCode) {
                                    this.thisquotationseatransportlines[qlIndex].checkboxes.push({
                                        id: st.id, 
                                        name: st.surcharge_type.name,
                                        label: st.surcharge_type.label ? st.surcharge_type.label : st.surcharge_type.name,
                                        should_show: surchargeList ? shouldShowFromEdit : 0
                                    });
                                }
                                if(!rates[st.surcharge_type.id]) rates[st.surcharge_type.id] = [];
                                this.masterRates.forEach((rate, rindex) => {
                                    const rcId = Number(rate.carrier_id), rpId = rate.port_of_loading_id;
                                    let hasCarrier = carrierIds.includes(rcId) && polIds.includes(rpId);
                                    if(alreadyDone.includes(st.surcharge_type.code + rindex)){
                                        return;
                                    }
                                    let shouldShow = hasCarrier ? true : false;
                                    if(st.surcharge_type.code == this.trackedCode) shouldShow = quoteLine.tracked;
                                    else if(st.surcharge_type.code == this.towedCode) shouldShow = quoteLine.towed;
                                    else if(isInList) shouldShow = shouldShowFromEdit;
                                    else if(st.surcharge_type.optional) shouldShow = false;
                                    rates[st.surcharge_type.id][rindex] = {
                                        sales_rate: hasCarrier ? addedChargeSales : '',
                                        sales_rate_currency_id: st.sales_rate_currency_id,
                                        sales_rate_calculation_unit_id :st.sales_rate_unit_id,
                                        buying_rate: hasCarrier ? addedChargeBuying : '',
                                        buying_rate_currency_id: st.buying_rate_currency_id,
                                        buying_rate_calculation_unit_id :st.buying_rate_unit_id,
                                        carrier_id: rcId,
                                        port_of_loading_id: rpId,
                                        surcharge_id: surcharge.id,
                                        surcharge_code: st.surcharge_type.code,
                                        is_optional: st.surcharge_type.optional,
                                        should_show: shouldShow ? 1 : 0,
                                        surcharge_item_commodity_surcharge_type_id: st.id
                                    };
                                    if(hasCarrier) alreadyDone.push(st.surcharge_type.code + rindex);
                                });
                            });
                            break;
                        }
                    }
                })
            })
            for(let i in rates){
                let scode = 0, sid = 0, optional = 0;
                for(let j in rates[i]){
                    scode = rates[i][j].surcharge_code;
                    sid = rates[i][j].surcharge_id;
                    optional = rates[i][j].is_optional;
                }
                this.thisquotationseatransportlines.splice(spliceIndex, 0, this.getNewSurcharge(rates[i], scode, sid, optional));
                spliceIndex++;
            }
        },
        addManualSurcharge(carrier, index){
            let rates = [];
            let spliceIndex = index + 1;
            while(this.thisquotationseatransportlines[spliceIndex]){
                if(this.thisquotationseatransportlines[spliceIndex].is_surcharge)
                    spliceIndex++;
                else break;
            }
            this.masterRates.forEach(rate => {
                const hasCarrier = (carrier.carrier_id ? carrier.carrier_id : carrier.id) == rate.carrier_id && (carrier.port_of_loading_id ? carrier.port_of_loading_id == rate.port_of_loading_id : true);
                rates.splice(spliceIndex, 0, {
                    sales_rate: hasCarrier ? '' : null,
                    sales_rate_currency_id: window.defaultCurrency,
                    sales_rate_calculation_unit_id: null,
                    buying_rate: hasCarrier ? '' : null,
                    buying_rate_currency_id: window.defaultCurrency,
                    buying_rate_calculation_unit_id: null,
                    carrier_id: rate.carrier_id,
                    port_of_loading_id: rate.port_of_loading_id,
                    surcharge_id: null,
                    surcharge_item_commodity_surcharge_type_id: null,
                    should_show: hasCarrier ? 1 : 0
                });
            });
            this.thisquotationseatransportlines.splice(spliceIndex, 0, this.getNewSurcharge(rates));
        },
        getNewSurcharge(rates, description = null, surchargeId = null, optional = false){
            return {
                'quantity': '1',
                'description': description,
                'sales_currency_id': window.defaultCurrency,
                'sales_price': null,
                'buying_currency_id': window.defaultCurrency,
                'buying_price': null,
                'commodity_id': '',
                'condition_id': '2',
                'calculation_unit_id': null,
                'surcharge_id': surchargeId,
                'is_surcharge': true,
                'quote_line_carriers': rates,
                'contract_id': null,
                'tariff_id': null,
                'uuid': Math.random(),
                'optional': optional
            };
        },
        getAllTotals(isQuantity = false){
            if(isQuantity) this.$parent.generalCostFillQuantities();
        },
        getSurchargeList(qlIndex){
            let i = qlIndex + 1;
            let max = this.thisquotationseatransportlines.length - 1;
            const surchargeList = [];
            while(i <= max){
                if(!this.thisquotationseatransportlines[i].surcharge_id) break;
                surchargeList.push(this.thisquotationseatransportlines[i]);
                i++;
            }
            return surchargeList.length > 0 ? surchargeList : false;
        },
        setTariffsWithConditions(item, index){
            if(!item.mayHaveTariff && !item.mayHaveContract) {
                this.setSurcharges(item, index, this.getSurchargeList(index));
                return; 
            }
            if(item.quote_line_carriers){
                item.quote_line_carriers.forEach(carr => {
                    carr.contract_id = null;
                    carr.tariffOrContractNr = null;
                    if(item.mayHaveContract) carr.mayHaveTariff = true;
                });
            }
            const contracts = this.$parent.contracts;
            if(contracts && contracts.length > 0 && item.mayHaveContract){
                for(let contract of contracts){
                    if(contract.commodity_id == item.commodity_id){
                        if(!contract.measurement_conditional_description) continue;
                        let explDescription = contract.measurement_conditional_description.split(/<br\/>|<br \/>/);
                        let newConditionals = [];
                        for(let desc of explDescription){
                            let descs = desc.split(' ');
                            newConditionals.push({
                                value: descs[2].replace(/\D/g, ""),
                                measurement_conditional_id: descs[0],
                                operator: descs[1] == '<=' ? '2' : '1'
                            })
                        }
                        let newComm = {...contract};
                        newComm.tariff_item_commodity_measurement_conditionals = newConditionals;
                        if(this.checkConditions(newComm, item)){
                            this.addItemsInContract(contract, index);
                            if(this.carriers.length == 1) return;
                        }
                    }
                }
            }
            this.$nextTick(() => this.setSurcharges(item, index, this.getSurchargeList(index)) );
            if(!item.tariffsChecked){
                this.prefillTariffs(index);
                return;
            }
            item.suggestedAlternative = null;
            let companyId = store.getters.getCompanyId;
            item.quote_line_carriers.forEach(itemTariff => {
                if(!itemTariff.mayHaveTariff || itemTariff.contract_id || !this.storedTariffs[item.commodity_id]) return;
                let foundData = false;
                let lastcomm = {tariff_item_commodity_measurement_conditionals: []};
                let hasCondValues = false;
                let wrongConditionWarning = false;
                for(let tariffCommodity of this.storedTariffs[item.commodity_id]){
                    let maybeWrongCondition = false;
                    let thiscomm = tariffCommodity.tariff_item_commodity_commodities.find(comm => (comm.commodity_id == item.commodity_id && (comm.condition_id == item.condition_id || comm.condition_id === null)));
                    if(thiscomm == null || itemTariff.carrier_id != tariffCommodity.carrier_id){
                        thiscomm = tariffCommodity.tariff_item_commodity_commodities.find(comm => comm.commodity_id == item.commodity_id);
                        if(thiscomm == null || itemTariff.carrier_id != tariffCommodity.carrier_id) continue;
                        maybeWrongCondition = true;
                    }
                    lastcomm = {...thiscomm};
                    let meetsConditions = this.checkConditions(lastcomm, item);
                    if(!meetsConditions){
                        this.removeTariffData(tariffCommodity, itemTariff, item, false);
                        if(meetsConditions !== null)
                            hasCondValues = true;
                        continue;
                    }
                    if(maybeWrongCondition){
                        wrongConditionWarning = true;
                    }
                    else{
                        this.setTariffData(tariffCommodity, itemTariff, item, false, companyId);
                        foundData = true;
                        item.suggestedAlternative = null;
                    }
                }
                if(!foundData){
                    itemTariff.tariff_id = null;
                    itemTariff.tariffOrContractNr = '';
                    if(wrongConditionWarning){
                        item.suggestedAlternative = this.$t('form.condition') + ': ' + this.$t(item.condition_id == 1 ? 'form.used' : 'form.new');
                    }
                    else if(hasCondValues){
                        this.findAlternativeComm(item, lastcomm);
                    }
                }
            });
        },
        checkConditions(thiscomm, item){
            let meetsConditions = true;
            for(let condition of thiscomm.tariff_item_commodity_measurement_conditionals){
                let compareFrom = Number(condition.value);
                let compareTo = this.getCompareTo(item, condition.measurement_conditional_id);
                if(!compareTo){
                    return null;
                }
                meetsConditions = compareTo && (condition.operator == '2' ? compareFrom >= compareTo : compareFrom < compareTo);
                if(!meetsConditions){
                    break;
                }
            }
            return meetsConditions;
        },
        findAlternativeComm(item, lastcomm){
            let condnotmet = '';
            for(let condition of lastcomm.tariff_item_commodity_measurement_conditionals){
                const condParam = this.conditionParams[condition.measurement_conditional_id];
                const paramName = typeof condParam == 'string' ? condParam : condParam[0];
                const paramTag = typeof condParam == 'string' ? condParam : condParam[1];
                condnotmet += (paramName + ' ' + (condition.operator == '1' ? '>' : '<=') + ' ' + condition.value + ' ' + paramTag + '<br />');
            }
            for(let sComm of this.commodities.all){
                if(sComm.id == item.commodity_id || !item.allTariffs[sComm.id]) continue;
                for(let tariffCommodity of item.allTariffs[sComm.id]){
                    const thiscomm = tariffCommodity.tariff_item_commodity_commodities.find(comm => (comm.commodity_id == sComm.id && (comm.condition_id == item.condition_id || comm.condition_id === null)));
                    if(thiscomm == null || (item.suggestedAlternative && item.suggestedAlternative.includes(sComm.name))){
                        continue;
                    }
                    let meetsConditions = this.checkConditions(thiscomm, item);
                    if(!meetsConditions){
                        break;
                    }
                    if(item.suggestedAlternative)
                        item.suggestedAlternative += ("<br />" + sComm.name);
                    else
                        item.suggestedAlternative = condnotmet + this.$t('sales.cond_not_met_suggestion') + ":<br />" + sComm.name;
                }
            }
            if(!item.suggestedAlternative) item.suggestedAlternative = this.$t('sales.cond_not_met_request');
        },
        setTariffData(tariffCommodity, itemTariff, item, newtariff, companyId){
            tariffCommodity.tariff_item_commodity_carriers.forEach(overviewTariff => {
                if(itemTariff.carrier_id == overviewTariff.carrier_id && itemTariff.port_of_loading_id == (newtariff ? newtariff.port_of_loading_id : tariffCommodity.port_of_loading_id)){
                    let market_tariff = newtariff ? tariffCommodity.tariff_item_commodity_market_levels.find(level => level.office_id == companyId) : tariffCommodity.tariff_item_commodity_market_levels[0];
                    if(!market_tariff) market_tariff = tariffCommodity.tariff_item_commodity_market_levels.find(level => level.office_id == (newtariff ? newtariff.tariff.office_creator_id : tariffCommodity.office_creator_id));
                    const origin = newtariff ? newtariff : tariffCommodity;
                    const currentQuarterOnly = origin.tariff.end_date < this.$parent.quotation.valid_till;
                    itemTariff.buying_rate = overviewTariff.buying_rate;
                    itemTariff.buying_rate_currency_id = overviewTariff.buying_rate_currency_id;
                    itemTariff.buying_rate_calculation_unit_id = overviewTariff.buying_rate_calculation_unit_id;
                    itemTariff.sales_rate = market_tariff ? market_tariff.amount : 0;
                    itemTariff.sales_rate_currency_id = market_tariff ? market_tariff.currency_id : window.defaultCurrency;
                    itemTariff.sales_rate_calculation_unit_id = market_tariff ? market_tariff.calculation_unit_id : tariffCommodity.calculation_unit_id;
                    itemTariff.tariff_id = origin.tariff_id;
                    item.is_pro_rata = tariffCommodity.is_pro_rata;
                    item.sales_price = market_tariff ? market_tariff.amount : 0;
                    item.sales_currency_id = market_tariff ? market_tariff.currency_id : window.defaultCurrency;
                    itemTariff.currentQuarterOnly = currentQuarterOnly || this.$parent.quarterOverlap;
                    itemTariff.tariffOrContractNr = 'tariff ' + origin.number + (currentQuarterOnly ? this.$t('sales.valid_x', [this.formatDate(origin.tariff.end_date, 'shortDate')]) : 
                                                                                (this.$parent.quarterOverlap ? this.$t('sales.valid_y', [this.formatDate(origin.tariff.start_date, 'shortDate')]) : ''));
                }
            });
        },
        removeTariffData(tariffCommodity, itemTariff, item, newtariff, companyId){
            tariffCommodity.tariff_item_commodity_carriers.forEach(overviewTariff => {
                if(itemTariff.carrier_id == overviewTariff.carrier_id){
                    const market_tariff = newtariff ? tariffCommodity.tariff_item_commodity_market_levels.find(level => level.office_id == companyId) : tariffCommodity.tariff_item_commodity_market_levels[0];
                    if(itemTariff.buying_rate == overviewTariff.buying_rate && itemTariff.buying_rate_currency_id == overviewTariff.buying_rate_currency_id && itemTariff.buying_rate_calculation_unit_id == overviewTariff.buying_rate_calculation_unit_id &&
                        itemTariff.sales_rate == (market_tariff ? market_tariff.amount : 0) && itemTariff.sales_rate_currency_id == (market_tariff ? market_tariff.currency_id : window.defaultCurrency) &&
                        itemTariff.sales_rate_calculation_unit_id == (market_tariff ? market_tariff.calculation_unit_id : tariffCommodity.calculation_unit_id)){
                        itemTariff.buying_rate, itemTariff.sales_rate = '';
                        itemTariff.buying_rate_currency_id, itemTariff.sales_rate_currency_id = window.defaultCurrency;
                        itemTariff.buying_rate_calculation_unit_id, itemTariff.sales_rate_calculation_unit_id = null;
                        itemTariff.tariff_id = null;
                        item.sales_price = '';
                        item.sales_currency_id = window.defaultCurrency;
                        itemTariff.tariffOrContractNr = null;
                    }
                }
            });
        },
        getCompareTo(item, measurementId){
            let compareTo = 0;
            switch(measurementId){
                case 1: case 'Length': compareTo = item.length; break;
                case 2: case 'Width':  compareTo = item.width; break;
                case 3: case 'Height': compareTo = item.height; break;
                case 4: case 'Weight': compareTo = item.weight; break;
                case 5: case 'm²': compareTo = ((item.length * item.width) / 10000); break; //m2
                case 6: case 'm³': compareTo = ((item.length * item.width * item.height) / 1000000); break; //m3
                case 7: case 'w/m': compareTo = Math.max(item.weight * 0.001, (item.length * item.width * item.height) / 1000000); break; //w/m
                default: compareTo = NaN;
            }
            return compareTo;
        },
        setInfoBlurbClasses(item){
            if(item.currentQuarterOnly) return 'bg-warning';
            if(item.contract_id != undefined) return 'bg-success text-white';
            return '';
        },
        setCommodityMode(line, index){
            if(!line.should_show){
                line.should_show = 1;
            }
            else{
                line.should_show = 0;
                let inPreferredList = 0;
                for(let cindex in this.commodities.preferred){
                    const comm = this.commodities.preferred[cindex];
                    if(comm.find(c => c.id == line.commodity_id)){
                        inPreferredList = true;
                        break;
                    }
                }
                if(!inPreferredList){
                    line.commodity_id = '';
                    this.prefillTariffs(index); 
                    this.emitOnChange();
                }
            }
        },
        showConditionals(e, comm){
            /*const message = (comm.message === true || comm.message === false || comm.message === undefined) ? ('<b>'+comm.name+'</b>') : ('<b>'+comm.name+'</b><br/>'+comm.message);
            const el = e.target.parentElement.parentElement.nextElementSibling;
            el.innerHTML = message;
            el.classList.add('d-block');*/
        },
        hideConditionals(e){
            /*const el = e.target.parentElement.parentElement.nextElementSibling;
            el.classList.remove('d-block');*/
        },
        setSelection(e, show = false, onclick = false){
            /*if(onclick && e.target.tagName == "SELECT"){
                e.preventDefault();
                e.target.focus();
                return;
            }
            if(show){
                e.target.setAttribute("size", this.commodities.size);
                e.target.style.width = "200px";
                e.target.classList.add('position-fixed');
            }
            else{
                e.target.removeAttribute("size");
                e.target.style.width = "100%";
                e.target.classList.remove('position-fixed');
            }*/
        },
        setSelectedCurrency(){
            this.$nextTick(() => {
                this.selectedCurrency.id = this.selCurrency;
                const curr = this.currencies.find(curr => curr.id == this.selCurrency);
                this.selectedCurrency.code = curr ? curr.code : null;
            });
        },
        checkRates(item, cindex, index, upperItem){
            let amount = 1;
            let p_rate = Number(item.buying_rate);
            let s_rate = Number(item.sales_rate);
            let p_curr = item.buying_rate_currency_id;
            let s_curr = item.sales_rate_currency_id;
            let itemForSurcharge = null;
            let validAmount = true;
            if(p_rate == s_rate && p_curr == s_curr && item.sales_rate_calculation_unit_id == item.buying_rate_calculation_unit_id)
                return '<div class="form-control form-control-sm margin-amount bg-light">0.00%</div>';
            if(item.sales_rate_calculation_unit_id == 5 || item.buying_rate_calculation_unit_id == 5){
                let i = index - 1;
                while(i >= 0){
                    if(this.thisquotationseatransportlines[i] && this.thisquotationseatransportlines[i].surcharge_id === null){
                        itemForSurcharge = this.thisquotationseatransportlines[i].quote_line_carriers[cindex];
                        break;
                    }
                    i--;
                }
            }
            if(item.sales_rate_calculation_unit_id == 5 && itemForSurcharge){
                s_rate *= (Number(itemForSurcharge.sales_rate) * 0.01);
                s_curr = itemForSurcharge.sales_rate_currency_id;
            }
            if(item.buying_rate_calculation_unit_id == 5 && itemForSurcharge){
                p_rate *= (Number(itemForSurcharge.buying_rate) * 0.01);
                p_curr = itemForSurcharge.buying_rate_currency_id;
            }
            if(upperItem){
                let lmLength = item.carrier_id == 13 && Number(upperItem.width) > 260 ? ((Number(upperItem.width) * Number(upperItem.length)) / 2.5) : Number(upperItem.length);
                if(item.buying_rate_calculation_unit_id == 2 || item.buying_rate_calculation_unit_id == 8){
                    p_rate *= (Number(upperItem.weight) * (item.buying_rate_calculation_unit_id == 8 ? 0.001 : 1));
                    if(upperItem.weight === null) validAmount = false;
                }
                if(item.sales_rate_calculation_unit_id == 2 || item.sales_rate_calculation_unit_id == 8){
                    s_rate *= (Number(upperItem.weight) * (item.sales_rate_calculation_unit_id == 8 ? 0.001 : 1));
                    if(upperItem.weight === null) validAmount = false;
                }
                if(item.buying_rate_calculation_unit_id == 3){
                    p_rate *= (lmLength * 0.01);
                    if(upperItem.length === null) validAmount = false;
                }
                if(item.sales_rate_calculation_unit_id == 3){
                    s_rate *= (lmLength * 0.01);
                    if(upperItem.length === null) validAmount = false;
                }
                if(item.buying_rate_calculation_unit_id == 4){
                    p_rate *= ((Number(upperItem.length) * Number(upperItem.width) * Number(upperItem.height)) / 1000000);
                    if(upperItem.length === null || upperItem.width === null || upperItem.height === null) validAmount = false;
                }
                if(item.sales_rate_calculation_unit_id == 4){
                    s_rate *= ((Number(upperItem.length) * Number(upperItem.width) * Number(upperItem.height)) / 1000000);
                    if(upperItem.length === null || upperItem.width === null || upperItem.height === null) validAmount = false;
                }
            }

            if(isNaN(s_rate) || (s_rate === Infinity) || isNaN(p_rate) || (p_rate === Infinity) || item.sales_rate_calculation_unit_id == 7) {
                validAmount = false;
            }

            let marginNormalized = 0;
            if(validAmount){
                const targetCurrency = this.currencies.find(curr => curr.id == s_curr);
                if(targetCurrency){
                    p_rate = this.calculateRoe(p_curr, p_rate, targetCurrency);
                    s_rate = this.calculateRoe(s_curr, s_rate, targetCurrency);
                }
                amount = ((s_rate / p_rate) - 1) * 100;
                marginNormalized = amount > 0 ? 1 : (marginNormalized < 0 ? -1 : 0)
                amount = (amount === Infinity || isNaN(amount)) ? '-' : amount.toFixed(2);
            }
            else {
                amount = '-';
            }
            const style = marginNormalized == 1 ? 'bg-success text-white' : (marginNormalized == -1 ? 'bg-danger text-white' : 'bg-light');
            return '<div class="form-control form-control-sm margin-amount '+style+'">' + (amount + '%') + '</div>';
        },
        calculateRoe(currId, value, targetCurrency){
            if(currId == targetCurrency.id){
                return value;
            }
            let currency = this.currencies.find(curr => curr.id == currId);
            if(!currency) currency = this.currencies.find(currency => currency.is_default == 1);
            if(currency.currency_rates && targetCurrency.currency_rates){
                const exRate = targetCurrency.currency_rates.exchange_rate_usd / currency.currency_rates.exchange_rate_usd;
                return exRate * value;
            }
            return value;
        },
        checkForDupes(unit){
            const els = document.querySelectorAll('#freight .chassis-nr');
            const numbersAlreadyChecked = [];
            unit.duplicate = null;
            for(let i = els.length - 1; i >= 0; i--) {
                const el = els[i];
                let thisChassisNumbers = el.value.toUpperCase();
                let identifier = Number(el.getAttribute('data-id'));
                let matchedUnit = null, originalUnit = null, matchedNumber = '';
                unitDuplicateFinder:
                for(let j = els.length - 1; j >= 0; j--) {
                    let jobUnit = this.thisquotationseatransportlines[j];
                    if(!jobUnit || !jobUnit.chassis_number) continue;
                    let chassisNumbers = jobUnit.chassis_number.toUpperCase().split('\n');
                    for(let chassis of chassisNumbers){
                        if(numbersAlreadyChecked.includes(chassis)) continue;
                        if(j == identifier){
                            originalUnit = jobUnit;
                        }
                        else if(chassis && thisChassisNumbers.match(new RegExp(`^${chassis}$`, 'm'))){
                            matchedUnit = jobUnit;
                            matchedNumber = chassis;
                            numbersAlreadyChecked.push(chassis);
                        }
                        if(originalUnit && matchedUnit) break unitDuplicateFinder;
                    }
                }
                if(originalUnit)
                    originalUnit.duplicate = matchedUnit ? {by: (matchedUnit.make && matchedUnit.model ? (matchedUnit.make + ' ' + matchedUnit.model) : 'a unit above'), number: matchedNumber} : null;
            }
            if(!unit.chassis_number || unit.duplicate){
                return;
            }
            JobUnitService.checkForDuplicates(null, unit.chassis_number.toUpperCase()).then(response => {
                unit.duplicate = response.data;
            });
        },
        getQuoteLineRel(item, checkField, idId = false){
            let result = false;
            item.quote_line_carriers.forEach(qlc => {
                if(qlc[checkField]) result = (idId ? qlc[checkField] : qlc.tariffOrContractNr);
            });
            return result;
        },
        isInSurcharges(value){
            let result = false;
            this.surchargeTypes.forEach(surchargeType => {
                if(surchargeType.code === value){
                    result = true;
                    return;
                }
            });
            return result;
        },
        setZeroOnEmpty(e, item){
            if(item.buying_rate === ''){
                e.target.value = '0';
                item.buying_rate = '0';
            }
            if(item.sales_rate === ''){
                e.target.value = '0';
                item.sales_rate = '0';
            }
        },
        setMakeModel(value, item){
            item.description = value ? null : '';
        },
        shouldShowSurcharge(seatransportline, cindex, index, checkDiscretely = false){
            const fg = this.forceGet(seatransportline, cindex);
            if(seatransportline.description == this.trackedCode) return fg.sales_rate != '' && this.checkTrackedTowed(index, 'tracked');
            if(seatransportline.description == this.towedCode) return fg.sales_rate != '' && this.checkTrackedTowed(index, 'towed');
            if(seatransportline.optional) return fg.sales_rate != '' && this.checkTrackedTowed(index, seatransportline.description, true);
            return checkDiscretely ? (fg.sales_rate !== null || fg.buying_rate !== null) : (fg.sales_rate ? 1 : 0);
        },
        checkChassisNr(e){
            const length = e ? e.length : 0;
            return length == 17 ? "#dfd" : (length > 17 ? "#fdd" : "#fff");
        },
        isPartOfLumpsum(index, cindex){
            for(let i = index - 1; i >= 0; i--){
                let qlc = this.thisquotationseatransportlines[i].quote_line_carriers;
                if(qlc && qlc[cindex] && !qlc[cindex].surcharge_id && !this.thisquotationseatransportlines[i].is_surcharge)
                    return qlc[cindex].sales_rate_calculation_unit_id == 7;
            }
            return false;
        },
        autoFillSalesRates(tindex, field, e){
            if(this.isForwarding) return;
            this.thisquotationseatransportlines[tindex].quote_line_carriers.forEach(item => {
                if(!item.contract_id) item[field] = e.target.value;
            })
        },
        addItemsInContract(contr, index){
            let item = this.thisquotationseatransportlines[index];
            let contract = this.hardCopy(contr);
            item.quote_line_carriers.forEach((qlc, index) => {
                const carrierId = Number(qlc.carrier_id), polId = Number(qlc.port_of_loading_id);
                if(contract.carriers.includes(carrierId) && contract.loading_ports.includes(polId)){
                    const qlc = contract.quote_line_carrier;
                    qlc.carrier_id = carrierId;
                    qlc.port_of_loading_id = polId;
                    qlc.tariffOrContractNr = 'contract ' + contract.number + (qlc.currentQuarterOnly ? this.$t('sales.valid_x', [this.formatDate(contract.end_date, 'shortDate')]) : 
                                                                           (this.$parent.quarterOverlap ? this.$t('sales.valid_y', [this.formatDate(contract.start_date, 'shortDate')]) : ''));
                    qlc.currentQuarterOnly = qlc.currentQuarterOnly || this.$parent.quarterOverlap;
                    item.quote_line_carriers[index] = qlc;
                }
            });
            if(!contract.is_based_off_tariffs){
                item.condition_id = contract.condition_id;
            }
            item.tariff_id = null;
            item.surcharge_id = null;
            item.suggestedAlternative = null;
            item.sales_currency_id = contract.sales_currency_id;
            item.sales_price = contract.sales_price;
            item.buying_currency_id = contract.buying_currency_id;
            item.buying_price = contract.buying_price;
            item.is_pro_rata = contract.is_pro_rata;
            this.$nextTick(() => {
                this.setSurcharges(item, index, this.getSurchargeList(index));
                this.getAllTotals();
                this.$parent.disableStepFour();
            });
        },
        showDialog(formType, line){
            this.showMakeForm = formType == 'make';
            this.showModelForm = formType == 'model';
            this.formRef = formType == 'make' ? this.$refs.makeForm : this.$refs.modelForm;
            document.getElementById('makemodel-dialog').showModal();
            this.formRef.reset();
            if(formType == 'model'){
                this.formRef.model.unit_make_id = line.unit_make_id;
                this.formRef.model.commodity_group_id = this.commodities.all.find(c => c.id == line.commodity_id).commodity_group_id;
            }
            this.storedData = line;
        },
        closeModal(data = 0, field = null){
            if(data){
                this.storedData[field] = data;
                this.storedData['unit_' + field + '_id'] = data.id;
            }
            this.showMakeForm = this.showModelForm = false;
            document.getElementById('makemodel-dialog').close();
        },
        saveMakeModel(){
            return this.formRef.submit();
        },
        devalidate(e){
            if(e.target.value){
                e.target.classList.remove('is-invalid');
            }
        },
        forceGet(item, index){
            if(item != undefined && item.quote_line_carriers != undefined && item.quote_line_carriers[index] != undefined){
                return item.quote_line_carriers[index];
            }
            return this.masterRates;
        },
        hardCopy(obj){
            return JSON.parse(JSON.stringify(obj));
        },
        loadData() {
            if(this.dataItemsLoaded > 0) return;
            surchargetypeService.index(null, null, 'surchargetype').then(response => {
                this.surchargeTypes = response.data;
                this.filteredSurchargeTypes = response.data.filter(st => (st.code != this.trackedCode && st.code != this.towedCode));
                this.dataItemsLoaded+=3;
            }).catch(error => console.log('error', error) )
        }
    }
}
</script>

<style scoped>
    .col-form-label-sm{ margin-bottom: 0 !important }
    .transport-unit-table .col-sm-1{ width: 8% !important }
    .transport-unit-table .col-sm-1p5{ width: 10% !important }
    .transport-unit-table .col-sm-2{ width: 15.5% !important }
    .transport-unit-table .col-sm-3{ width: 20% !important }
    .lp-yes{ width: 129px }
    .lp-no{ width: 268px }
    .transport-unit-table.row, .transport-unit-table > div{
        --bs-gutter-x: 0.5rem !important;
        margin-bottom: 5px
    }
    disable.transport-unit-table.barren > label{
        visibility: hidden
    }
    .transport-unit-table textarea{
        position: absolute;
        max-height: 100px !important
    }

    .border-left{
        border-left: 4px solid gray;
    }

    .surcharge.disabled{
        background-color: #e9ecef
    }

    .totals-row .col-sm-1 div{
        border: 1px solid #ccf;
        border-radius: 4px;
        padding: 2px 8px;
        color: #999
    }

    .percentage-red{
        background-color: #d00;
        border: 1px solid black !important;
        color: #fff !important
    }
    .percentage-green{
        background-color: #0a0;
        border: 1px solid black !important;
        color: #fff !important
    }

    .popover-bubble{
        display: none;
        position: absolute;
        width: 200px;
        border: 1px solid black;
        text-align: center;
        border-radius: 4px;
        background-color: white;
        translate: -90px calc(-36px - 100%);
    }
    .popover-trigger:hover{ cursor: help }
    .popover-trigger:hover+.popover-bubble{ display: block !important }
    .bubble-xl{ 
        width: 300px !important;
        transform: translate(-125px, 2px) !important
    }
    .bubble-commodity{
        width: 250px !important;
        transform: translate(60px, 28px) !important
    }
    .side-cts-button{
        background-color: #ccc;
        color: black;
        text-decoration: none;
        display: block;
        border-radius: 50px;
        width: 28px;
        padding: 2px 0px 4px 10.5px;
    }
    .col-sm-s{
        flex: 0 0 auto;
        width: 80px
    }
    .col-sm-xs{
        flex: 0 0 auto;
        width: 65px
    }
    .input-group-text{
        font-size: 0.75rem;
        padding: 6px;
        height: 28px !important
    }
    .make-model-switch{
        text-decoration: underline;
        cursor: pointer
    }
    .input-group input{
        height: 28px
    }
    .row:empty{
        margin: 0 !important
    }
</style>