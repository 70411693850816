<template>
    <li id="fileElement" class="nav-link ms-auto" role="presentation" v-if="parentItemName != 'job'">
        <button :class="getButtonClasses" id="files-tab" data-bs-toggle="tab" data-bs-target="#files" type="button" role="tab" aria-controls="files" aria-selected="true" @click="hideFooterButtons">
            {{$tc('form.files', 2)}}&ensp;
            <span class="badge" v-if="files && filesLength" :class="{'orange': ($parent.quotation && $parent.quotation.status == 'accepted') || $parent.job}">{{filesLength}}</span>
        </button>
    </li>
    <div id="dropzone" class="dropzone dropzone-hidden" @dragover="dropzoneDragOver" @drop="dropzoneDrop" v-if="!disabled">
        <div class="dropzone-text">
            <span class="h1">{{$t('form.drag_drop_1')}}</span>
            <br><br>
            <span class="h5">{{$t('form.drag_drop_2')}}</span>
        </div>
    </div>
    <dialog id="filetype-dialog">
        <div class="d-flex justify-content-between">
            <h5 class="pt-2 pb-4">{{$t('form.file_type_choose')}}</h5>
            <button class="btn mb-3 pt-0" @click="dismissDialog" tabindex="-1"><i class="fa fa-lg fa-close"></i> </button>
        </div>
        <div class="row py-1" v-for="file in filesToAdd" :key="file.name">
            <div class="col-6 text-truncate">{{file.name}}</div>
            <div class="col-6 text-truncate"> <FormItem v-model="file.type" type="select" :options="typeOptions" /> </div>
        </div>
        <form method="dialog" class="d-flex justify-content-end mt-4">
            <button class="btn btn-success col-5" :disabled="filesToAdd.find(file => file.type == null)" @click="confirmDialog(true)">{{ $t('form.save') }}</button>
        </form>
    </dialog>
    <div :class="{'tab-pane fade' : parentItemName != 'job'}" id="files" role="tabpanel" aria-labelledby="files-tab" style="min-height: 10rem">
        <h5 class="mt-4 mb-3 fw-bold">
            <span v-if="files && filesLength">{{$tc('form.files', 2)}}</span>
            <span v-else>{{$t('form.no_yet', [$tc('form.files', 2)])}}</span>
        </h5>
        <div class="row mt-2 align-items-center" v-for="(file, index) in (files ? files.filter(flx => !deletedFiles.includes(flx.id)) : [])" :key="index">
            <div class="col-auto" style="width: 2rem"><i :class="'fa-thin fa-'+getFileIcon(file.type)"></i></div>
            <a class="col-auto text-truncate" style="width: calc(40% - 2rem)" href="#" @click="download(file)" v-if="isLoggedIn">{{file.name ? file.name : file.path}}</a>
            <div v-else class="col-5 text-truncate">{{file.name}}</div>
            <div class="col-auto" style="width: calc(16% - 3rem)"><span v-show="$parent.quotation || $parent.job">{{file.type}}</span></div>
            <div class="col-1">{{file.size}}</div>
            <div class="col-1">{{formatDate(file.created_at ? file.created_at.split('T')[0] : file.date, 'shortDate')}}</div>
            <div class="col-3">
                <span>
                    <template v-if="file.user.profile_image">
                        <img :src="file.user.profile_image" alt="" width="32" height="32" class="rounded-circle me-2 chat-profile-image" />
                    </template>
                    <template v-else>
                        <div class='rounded-circle me-2 bg-primary text-white text-center chat-profile-image'>{{getInitials(file.user.name)}}</div>
                    </template>
                </span>
                &ensp;{{file.user.name ? file.user.name : file.user}}
            </div>
            <div class="col-auto" style="width: 2rem" v-if="file.id">
                <button class="col-auto btn p-0" style="width: 60px" type="button" @click="removeFile(file, index)"><i class="fa fa-close text-danger"></i></button>
            </div>
        </div>
        <input type="file" multiple="" class="d-none" @change="dropzoneDrop($event.target.files, true)" />
        <div class="btn file-drop mt-4 w-100 text-center" @click="$event.target.previousElementSibling.click()"> {{$t('form.drop_files')}} </div>
    </div>
</template>

<script>
    import store from '@/store/user'
    import fileService from "@/services/FileService";
    import moment from 'moment';
    export default {
        name: 'Files',
        props: ['files', 'tabClass', 'parentItemName'],
        emits: ['addFile'],
        data() {
            return {
                disabled: false,
                deletedFiles: [],
                toggleActive: false,
                filesToAdd: [],
                isManual: false,
                isAttachment: false,
                typeOptions: [
                    {id: "Commercial invoice", name: "Commercial invoice"},
                    {id: "Packing list", name: "Packing list"},
                    {id: "Pictures", name: "Pictures"},
                    {id: "Custom document", name: "Custom document"},
                    {id: "Certificates", name: "Certificates"},
                    {id: "Other", name: "Other"}
                ]
            }
        },
        computed: {
            isLoggedIn() {
                return store.getters.isLoggedIn;
            },
            getButtonClasses() {
                return "nav-link ms-auto pt-0 colour-black";
            },
            filesLength(){
                return this.files.filter(flx => !this.deletedFiles.includes(flx.id)).length;
            }
        },
        methods: {
            dropzoneShow(e){
                if(!this.disabled){
                    this.dropzoneTarget = e.target;
                    const el = document.getElementById('dropzone');
                    if(el) el.classList.replace('dropzone-hidden', 'dropzone-show');
                }
            },
            dropzoneHide(e){
                if(!this.disabled && (e.target === this.dropzoneTarget || e.target === document)){
                    const el = document.getElementById('dropzone');
                    if(el) el.classList.replace('dropzone-show', 'dropzone-hidden');
                }
            },
            dropzoneDragOver(e){
                e.preventDefault();
            },
            async dropzoneDrop(e, onManual = false, onAttachment = false){
                if(!onManual) e.preventDefault();
                this.filesToAdd = [];
                let isValid = true;
                if (onManual || e.dataTransfer.files) {
                    let files = onManual ? e : e.dataTransfer.files;
                    const length = files.length + 1 - 1;
                    
                    for (var i = 0; i < length; i++) {
                        let file = files[i];

                        if(!file.name){
                            file = file.getAsFile();
                        }

                        if(file.name.match(/\.csv$|\.doc?$|\.docx?$|\.xls?$|\.xls(x|m)$|\.pdf?$|\.eml?$|\.msg?$|\.png?$|\.gif?$|\.jpg?$|\.jpeg?$|\.bmp?$|\.tif?$|\.tiff$/i)){
                            if(this.$parent.quotation || this.$parent.job){
                                this.filesToAdd.push({
                                    'type': null,
                                    'name': file.name,
                                    'size': Math.ceil(file.size / 1024) + ' kb',
                                    'date': moment(),
                                    'user': store.getters.getUser,
                                    'blob': await this.blobToBase64(file),
                                    'url': window.URL.createObjectURL(file)
                                });
                            }
                            else{
                                this.addFileItem(file)
                            }
                        }
                        else {
                            this.$toast.error(this.$t('errors.file_not_supported'));
                            isValid = false;
                            break;
                        }
                    }
                }
                if(!onManual) this.dropzoneHide(e);
                if((this.$parent.quotation || this.$parent.job) && isValid){
                    this.isManual = onManual;
                    this.isAttachment = onAttachment;
                    document.getElementById('filetype-dialog').showModal();
                }
            },
            download(file){
                fileService.download(file.id).then(response => {
                    this.triggerDownload(response.data, file.name);
                }).catch(error => this.toastError(error) )
            },
            setDisabled(value){
                this.disabled = value;
            },
            hideFooterButtons(e){
                const main = document.getElementById('main-inv-tab');
                window.dontShowDropzone = false;
                if(main && this.toggleActive){
                    main.click();
                    const b1 = document.getElementById('nextbutton'), b2 = document.getElementById('savebutton');
                    this.disabled = true;
                    if(b1) b1.style.display = 'inherit';
                    if(b2) b2.style.display = 'inherit';
                    this.toggleActive = false;
                }
                else{
                    const b1 = document.getElementById('nextbutton'), b2 = document.getElementById('savebutton');
                    this.disabled = false;
                    if(main){
                        this.toggleActive = true;
                    }
                    if(b1) b1.style.display = 'none';
                    if(b2 && !(this.$parent.quotation && this.$parent.quotation.status != 'draft')) b2.style.display = 'none';
                }
            },
            dismissDialog(e){
                this.filesToAdd = [];
                let dialog = e.target.parentElement.parentElement;
                if(dialog.tagName != "DIALOG") dialog = dialog.parentElement;
                dialog.close();
            },
            confirmDialog(){
                if(this.isAttachment && this.$parent.quotation){
                    this.filesToAdd.forEach(file => this.$parent.quotation.attachments_info.push(file));
                }
                else{
                    this.addFiles(this.filesToAdd);
                }
            },
            async addFileItem(file) {
                let fileSize = Math.ceil(file.size / 1024) + ' kb';
                let fileType = file.type.split('/')[1] ? file.type.split('/')[1] : file.name.split('.').at(-1);
                let user = store.getters.getUser;
                delete user.profile_image;
                this.addFiles({
                    'type': fileType,
                    'name': file.name,
                    'size': fileSize,
                    'date': moment(),
                    'user': user,
                    'blob': await this.blobToBase64(file)
                });
            },
            async blobToBase64(blob) {
                return new Promise((resolve, _) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result.substr(reader.result.indexOf(',')+1));
                    reader.readAsDataURL(blob);
                });
            },
            addFiles(fileInfo){
                const parent = this.$parent[this.parentItemName];
                if (parent.id) {
                    const modelName = this.$parent.quotation ? 'quote' : this.parentItemName;
                    fileService.upload({"file_info": fileInfo, "id": parent.id, "model": modelName}).then(response => {
                        const messages = [];
                        for(let file of response.data) {
                            parent.file_info.unshift(file);
                            messages.push('uploaded ' + file.type + ': ' + file.name);
                            this.$forceUpdate();
                        }
                        if(this.$parent.quotation || this.parentItemName == 'job'){
                            this.$parent.$refs.contactHistory.addMessage({
                                content: messages.join("\n"),
                                user_id: 1,
                                user: {name: store.getters.getName},
                                created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                            });
                        }
                    }).catch(error => console.log('error', error) );
                } else {
                    for(let file of fileInfo) parent.file_info.unshift(file); 
                }
            },
            removeFile(file, index){
                if(confirm(this.$t('form.are_you_sure_delete') + " " + file.name + "?")){
                    fileService.delete(file.id).then(response => {
                        this.deletedFiles.push(file.id);
                        this.$forceUpdate();
                    }).catch(error => this.toastError(error) );

                    if(this.$parent.quotation || this.parentItemName == 'job'){
                        this.$parent.$refs.contactHistory.addMessage({
                            content: "deleted file: " + file.name,
                            user_id: 1,
                            user: {name: store.getters.getName},
                            created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                        });
                    }
                }
            }
        },
        mounted() {
            window.vuePointer = this;
            this.deletedFiles = [];
            this.$nextTick(() => {
                this.dragEnterEvent = window.addEventListener("dragenter", function(e){ // drag start
                    if(window.dontShowDropzone || e.target.hasAttribute('draggable')){
                        return;
                    }
                    e.preventDefault();
                    window.vuePointer.dropzoneShow(e);
                });
                this.dragLeaveEvent = window.addEventListener("dragleave", function(e){ // drag stop
                    if(window.dontShowDropzone) return;
                    e.preventDefault();
                    window.vuePointer.dropzoneHide(e);
                });
                this.dragDropEvent = window.addEventListener("drop", function(e){ // drop
                    if(window.dontShowDropzone) return;
                    e.preventDefault();
                });

                //Set files tab
                if(this.parentItemName != 'job'){
                    let fragment = new DocumentFragment();
                    fragment.append(document.getElementById('fileElement'));
                    document.querySelector(this.tabClass ? this.tabClass : 'ul.nav').appendChild(fragment);
                }
            });
        },
        unmounted(){
            window.removeEventListener("dragenter", this.dragEnterEvent);
            window.removeEventListener("dragleave", this.dragLeaveEvent);
            window.removeEventListener("drop", this.dragDropEvent);
        }
    }

</script>

<style scoped>
    .dropzone{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999999;
        background-color: rgba(0,0,0,0.5);
        transition: visibility 180ms, opacity 180ms;
    }
    .dropzone-hidden{
        visibility: hidden;
        opacity: 0
    }
    .dropzone-text {
        position: fixed;
        width: 100%;
        top: calc(50vh - 50px);
        color: #fff;
        text-align: center
    }
    .badge {
        background-color: #aaa;
        color: black;
        border-radius: 10em
    }
    .orange{
        background-color: #FFA000 !important
    }
    .chat-profile-image {
        width: 32px;
        height: 32px;
        display: inline-flex;
        justify-content: center;
        align-items: center
    }
    #filetype-dialog{
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.2);
        border-radius: .3rem;
        min-width: 40rem;
        max-width: 42rem !important;
        margin-top: auto !important
    }
    .file-drop{
        border: 3px dashed #888;
        color: #888;
        text-align: center;
        width: 50%;
        padding: 1rem;
        border-radius: 0.5rem
    }
    .file-drop:hover{
        border-color: #0d6efd !important;
        color: #0d6efd !important;
        cursor: pointer
    }
</style>